import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { Helpers } from '../../utils/helpers';
import * as Survey from 'survey-jquery-ui';
import { StylesManager, FunctionFactory } from 'survey-core';
import moment from 'moment';
import { DefaultLight } from 'survey-core/themes';
import QueryString from 'qs';

export class SurveyjsQuestionnaireFormControllerShared extends Controller {
  static targets = ['surveyContainer', 'nextButton', 'backButton'];

  initialize() {
    this.sidebar = false;
  }

  connect() {
    this.postMessageSent = false;

    FunctionFactory.Instance.register('age', this.age);
    FunctionFactory.Instance.register('ageByMonth', this.ageByMonth);
    FunctionFactory.Instance.register('ageByDays', this.ageByDays);
    StylesManager.applyTheme(DefaultLight);

    this.survey = this.buildSurvey();
    const onPostMessageDebounced = Helpers.debounce(this.onPostMessage.bind(this), 100);
    this.survey.locale = this.data.get('locale');
    this.survey.onComplete.add(this.submitAnswers);
    this.survey.showCompletedPage = false;
    this.survey.onAfterRenderQuestion.add(() => onPostMessageDebounced());
    this.survey.onAfterRenderPage.add(() => this.onPageLoad());
    if (this.sidebar) {
      this.survey.onAfterRenderPage.add(() => this.changeLastPageButtonLabel());
      this.survey.onAfterRenderPage.add(() => this.toggleFirstPageBackButton());
      this.survey.onComplete.add(() => this.closeSidebarOnFinish());
    } else {
      this.survey.onCurrentPageChanging.add(() => this.disableOnSubmit.showOverlay());
      this.survey.onCurrentPageChanged.add(() => this.disableOnSubmit.hideOverlay());
    }
    this.fileUploadUrl = this.data.get('fileUploadUrl');
    this.survey.onUpdateQuestionCssClasses.add(function (sender, options) {
      if (options.question.getType() === 'file') {
        options.cssClasses.removeButton = 'hidden';
      }
    });

    this.survey.onUploadFiles.add((survey, options) => {
      let formData = new FormData();
      options.files.forEach(function (file) {
        formData.append('attachment', file);
      });
      let xhr = new XMLHttpRequest();
      xhr.open('POST', this.fileUploadUrl);
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      xhr.onload = function () {
        const data = JSON.parse(xhr.response);
        if ('errors' in data) {
          data['errors'].forEach(error => {
            options.question.addError(error);
          });
        } else
          options.callback(
            'success',
            options.files.map(function (file) {
              return {
                file: file,
                content: data[file.name]['blob_id']
              };
            })
          );
      };
      xhr.send(formData);
    });

    this.survey.showNavigationButtons = false;
    this.survey.onValueChanged.add((sender, options) => {
      const isOtherCheckbox =
        options.question.getType() === 'checkbox' && Array.isArray(options.value) && options.value.includes('other');
      // hiding validation message when 'other' has been selected but custom text has not been added yet
      if (isOtherCheckbox) options.question.clearErrors();
    });
    this.survey.render(this.surveyContainerTarget);
  }

  age = date => {
    const today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var months = today.getMonth() - birthDate.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  ageByMonth = date => {
    const today = moment().startOf('day');
    var selectedDate = moment(date.toString());
    var difference = moment.duration(today.diff(selectedDate));
    return difference.months();
  };

  ageByDays = date => {
    const today = moment().startOf('day');
    var selectedDate = moment(date.toString());
    var differenceInDays = today.diff(selectedDate, 'days');
    return differenceInDays;
  };

  nextPage(e) {
    e.preventDefault();

    if (this.survey.isLastPage) {
      if (!this.survey.ignoreValidation && this.survey.currentPage.hasErrors()) return;

      this.submitAnswers(this.survey);
    } else {
      this.survey.nextPage();
    }
  }

  prevPage() {
    if (this.survey.isFirstPage) {
      this.backButtonTarget.disable = true;
      const backPath = this.backButtonTarget.dataset.backPath;
      window.location.href = backPath;
    } else {
      this.survey.prevPage();
    }
  }

  submitAnswers = sender => {
    Rails.ajax({
      type: 'POST',
      url: this.data.get('submit-url'),
      dataType: 'json',
      data: QueryString.stringify(
        {
          answers: sender.getPlainData({ includeEmpty: true, includeQuestionTypes: true })
        },
        { arrayFormat: 'brackets' }
      )
    });
  };

  buildSurvey = () => {
    const requiresQuestionnaire = this.data.get('requires-questionnaire') == 'true';
    const survey = new Survey.Model(this.data.get('configuration'));
    const data = JSON.parse(this.data.get('answers'));

    survey.ignoreValidation = !requiresQuestionnaire;
    survey.checkErrorsMode = 'onValueChanged';
    survey.data = data;

    return survey;
  };

  onPageLoad = () => {
    this.postMessageSent = false;
  };

  onPostMessage() {
    if (this.postMessageSent) return;

    const message = {
      sender: 'hh-booking-widget',
      data: {
        height: document.getElementById('main-content').clientHeight
      }
    };

    window.parent.postMessage(message, '*');
    this.postMessageSent = true;
  }

  get disableOnSubmit() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('body'),
      'disable-submit-overlay'
    );
  }
}
