import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = ['wrapper', 'input'];

  connect() {
    this.onSearching = debounce(this.onSearching, 500);
  }

  onSearching() {
    const url = this.wrapperTarget.dataset.url;
    const searchQuery = this.inputTarget.value;

    Rails.ajax({
      headers: {
        Accept: 'text/javascript'
      },
      converters: {
        'text script': function (text) {
          jQuery.globalEval(text, { nonce: document.querySelector('meta[name=csp-nonce]')?.content });
          return text;
        }
      },
      type: 'GET',
      url: url,
      dataType: 'json',
      data: new URLSearchParams({ search_query: searchQuery, searched: true }).toString()
    });
  }
}
